.LoadingOverlay {
  position: fixed;
  top: 0;
  left: 260px;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: rgba(0, 0, 0, 0.25);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.LoadingOverlay__Spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #262B40;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
